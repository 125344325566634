import React from 'react'
import { Helmet } from "react-helmet";
import { siteDescription, siteName, siteURL } from '../../config'

export default function Head ({ title: _title, description, picture, url }) {
  const title = _title ? `${_title} | ${siteName}` : siteName

  return (
    <Helmet>
      <title>{ title }</title>
      <meta name="description" content={description}/>

      { /* Schema.org markup for Google+ */ }
      <meta itemProp="name" content={title}/>
      <meta itemProp="description" content={description}/>
      <meta itemProp="image" content={picture}/>

      { /* Twitter Card Data */ }
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={title}/>
      <meta name="twitter:description" content={description}/>
      { /* Twitter summary card with large image must be at least 280 x 150px */ }
      { /* Recommendation: Minimum size: 300 x 157px. Maximum size: 4096 x 4096px  */}
      { /* Recommendation: Use a 2:1 aspect ratio image (eg: 1200 x 600px) */}
      { /* See: https://iamturns.com/open-graph-image-size/ */ }
      <meta name="twitter:image" content={picture}/>

      { /* Open Graph Data */ }
      <meta property="og:title" content={title}/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={url}/>
      { /* The recommended image size is 1200 x 630px */ }
      <meta property="og:image" content={picture}/>
      <meta property="og:description" content={description}/>
      <meta property="og:site_name" content={siteName}/>
    </Helmet>
  )
}

Head.defaultProps = {
  description: siteDescription,
  picture: siteURL + '/static/images/banner.png',
  url: siteURL,
}


