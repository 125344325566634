import React from 'react'
import styled from 'styled-components'
import { Loader as RLoader } from 'rsuite'

export default function Loader ({ loading }) {
  if (loading) {
    return (
      <Container>
        <RLoader size="lg"/>
      </Container>
    )
  } else {
    return null
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
`