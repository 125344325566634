import React from 'react'
import styled from 'styled-components'
import CellPhone from '../assets/cellphone.png'
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../../breakpoints'

export default function Title () {
  return (
   <Container>
     <Image src={CellPhone} />
     <TitleContainer>
       <Text><label style={{ backgroundColor: '#2AB155'}}>Potencia tu experiencia </label>descargando nuestra aplicación móvil</Text>
     </TitleContainer>
   </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

const Image = styled.img`
  display: inline-block;
  width: auto;
  height: 8.8rem;
  
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    height: 20rem;
    margin-bottom: 2rem;
  }
`

const TitleContainer = styled.div`
  border-left: 0.4rem solid #FFF;
  padding: 0 1.5rem;
  margin-left: 1.5rem;
  display: inline-block;
  max-width: 60rem;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    padding: 0.5rem  0 0 1.5rem ;
    border: none;
    text-align: center;
    margin: 0;
  }

  @media screen and (max-width: ${SM_BREAKPOINT}em) {
    padding-right: 1rem;
  }
`

const Text = styled.p`
  font-weight: 800;
  margin: 0;
  font-size: 2.7rem;
  color: #FFFFFF;
  
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 1.7rem;
  }
`
