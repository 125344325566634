import React from "react";
import styled from "styled-components";
import { Button, Modal } from "rsuite";
import ReactPlayer from "react-player";

export default function VideoModal({ show, onClose, url }) {
  return (
    <Container>
      <ModalCustom
        backdrop="static"
        show={show}
        onHide={onClose}
        size="fullpage"
      >
        <Modal.Header closeButton={false} style={{ paddingRight: 0 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Button onClick={onClose} appearance="primary">
              Cerrar
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer
            className="react-player fixed-bottom"
            url={url}
            width="100%"
            height="100%"
            controls={true}
            playing={true}
          />
        </Modal.Body>
      </ModalCustom>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const ModalCustom = styled(Modal)`
  margin: 0 auto;
  margin-top: 50px;

  width: 90%;
  max-width: 1200px;
  height: 740px;

  .rs-modal-content {
    overflow: hidden;
    padding: 8px;
  }
`;
