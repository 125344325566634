import React, { useState } from 'react'
import styled from 'styled-components'
import { PayPalButton } from 'react-paypal-button-v2'
import { Loader } from 'rsuite'
import { useHistory } from 'react-router-dom'

export default function PaypalButton ({ amount }) {
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  return (
   <Container>
     <PayPalButton
       amount={amount}
       style={{ size: 'responsive' }}
       createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                currency_code: "USD",
                value: amount
              }
            }],
          })
        }}
       onButtonReady={() => {
         setLoading(false)
       }}
       onCancel={(details, data) => {
         setLoading(false)
       }}
       onError={(details, data) => {
         history.push('/payment/failure')
       }}
       onSuccess={(details, data) => {
         history.push('/payment/success')
       }}
       options={{
          clientId: "AazLrPwkdrZ1yXseLAGXJchG4FFMGBN28NWYlR-pUtXz0UlFtp1jFuhQA26ucNY8zTojMFdS3FIwPWio"
        }}
     />
     {loading ? <Loader size="md" backdrop vertical style={{ zIndex: 999 }}/> : null}
   </Container>
  )
}

const Container = styled.div`
`