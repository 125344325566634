import React from 'react'
import styled from 'styled-components'

const Container = ({ children, banner, style }) => {
  return (
    <ImageContainer
      style={{ backgroundImage: `url(${banner})`, ...style }}
    >
      {children}
    </ImageContainer>
  )
}

const ImageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 0;
`

export default Container
