import React from 'react'
import styled from 'styled-components'
import LayoutContainer from './Container'
import { MD_BREAKPOINT } from '../../breakpoints'
import logo from '../../assets/images/logo.png'
import Button from '../Button'
import { Link } from 'react-router-dom'

export default function Header ({ showButton }) {
  return (
   <Container>
     <LayoutContainer>
       <Content>
         <Link to="/">
           <Logo src={logo}/>
         </Link>
         {showButton && <Button small to="/payment/method" rounded color="#2AB155">Pagar Congreso</Button>}
       </Content>
     </LayoutContainer>
   </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  background-color: #FFF;
  position: sticky;
  top: 0;
  z-index: 999;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Logo = styled.img`
  height: 6rem;
`