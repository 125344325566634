import React from 'react'
import styled from 'styled-components'
import { Button, Modal } from 'rsuite'

export default function SoonModal ({ show, onClose }) {
  return (
   <Container>
     <Modal backdrop="static" show={show} onHide={onClose} size="xs">
          <Modal.Body>
            El evento aún no está disponible. Mantengase informado/a a traves de las redes sociales de Congreso Veterinario para más novedades.
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} appearance="subtle">
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
   </Container>
  )
}

const Container = styled.div`
  
`