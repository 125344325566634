import React from 'react'
import styled from 'styled-components'

export default function Container ({ children, maxWidth, horizontalPadding, style, ...rest }) {
  return (
    <StyledContainer style={{ maxWidth: maxWidth, padding: `0 ${horizontalPadding}rem`, ...style }} {...rest}>
      {children}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: block;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
`

Container.defaultProps = {
  maxWidth: 1200,
  horizontalPadding: 2.5,
}

