import React from 'react'
import Container from '../Layout/Container'
import { MD_BREAKPOINT } from '../../breakpoints'
import styled from 'styled-components'

function Contact ({logo}) {
  return (
    <Container maxWidth={1000}>
      <Header >
          <LogoImg src={logo}/>
        <TextContainer>
          <P>+56 9 9234 8760</P>
          <P>malu@congresoveterinario.cl</P>
        </TextContainer>
      </Header>      
    </Container>
  )
}

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    justify-content: center;
  }
`
const TextContainer = styled.div`
 text-align: right;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    justify-content: space-between;
    width: 100%;
    text-align: center;
    margin-top: 0.75rem
  }
`

const P = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #FFF;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 20px;
    line-height: 28px;
  }
`

const LogoImg = styled.img`
  width: 166px;
  height: 55px;
`

export default Contact
