import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'rsuite'
import appStore from '../assets/appStore.png'
import playStore from '../assets/playStore.png'
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../../breakpoints'

export default function Download () {
  return (
   <Container>
     <a href="https://apps.apple.com/cl/app/congreso-veterinario/id1500643978" target="_blank">
      <StoreBadge src={appStore} alt="Download on the App Store"/>
     </a>

     <a href="https://play.google.com/store/apps/details?id=com.trazolabs.apolix.veterinario" target="_blank">
      <StoreBadge src={playStore} alt="Get it on Google Play"/>
     </a>
   </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: center;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    padding-top: 2rem;
  }

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 2.7rem;
  }
  
  @media screen and (max-width: ${SM_BREAKPOINT}em) {
    flex-direction: column;
  }
`

const StoreBadge = styled.img`
  width: 150px;
  max-height: 4.5rem;
  margin: 2rem 0.5rem;
`