import React from 'react'
import { Modal, Button } from 'rsuite'
import styled from 'styled-components'
import Banner from '../../../assets/images/bannerCyber.jpg'

export default function InfoModal () {
  const [ isOpen, setIsOpen ] = React.useState(true)

  return (
    <Container>

      <ModalCustom
        show={isOpen}
        onHide={() => setIsOpen(false)}
        >
        <img width="100%" src={Banner} alt="Banner informativo"/>
        <Modal.Footer>
          <Button style={{ marginTop: 10, marginBottom: 10, marginRight: 10 }} onClick={() => setIsOpen(false)} appearance="primary">
            Información Recibida
          </Button>
        </Modal.Footer>
      </ModalCustom>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const ModalCustom = styled(Modal)`
  margin: 0 auto;
  margin-top: 150px;
  width: 90%;
  max-width: 1200px;
 
 .rs-modal-content {
  overflow: hidden;
  padding: 0; 
 }
`



