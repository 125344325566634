import React from 'react'
import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "./Button";
import { MD_BREAKPOINT } from '../../breakpoints'
import styled from 'styled-components'
import axios from 'axios'
import Swal from 'sweetalert2'

const FormCard = () => {
  const { register, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [Email, setEmail] = useState("")
  const [Phone, setPhone] = useState("")
  const [Message, setMessage] = useState("")

  const handleSomething = async () => {
    try {
      setLoading(true)
      await axios.post('https://trazoevents.com/api/registrations/contact/send-message', { 
        FullName: name,
        Phone: Phone,
        Email: Email,
        Message: Message,
        Slug: 'veterinario-colchagua-2022'
      });
      setName('') 
      setEmail('')
      setMessage('')
      setPhone('')
      Swal.fire({
        title: '¡Correo enviado con éxito!',
        icon: 'success',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'swalbutton' //insert class here
        }
      })
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card >
      <Title >Escríbanos para obtener más información</Title>
      <Form onSubmit={handleSubmit((e) => handleSomething())}>
        <Input name="FullName" value={name} onChange={(e) => setName(e.target.value)} placeholder="Nombre completo" required />
        <Input name="Email" value={Email} onChange={(e) => setEmail(e.target.value)} placeholder="Correo" required/>
        <Input name="FullName" value={Phone} onChange={(e) => setPhone(e.target.value)} placeholder="Teléfono" required/>
        <TextArea name="FullName" value={Message} onChange={(e) => setMessage(e.target.value)} placeholder="Mensaje" required/>
        <Button buttonType="submit" buttonText="Enviar" loading={loading}/>
      </Form>
    </Card>
  )
}

const Card = styled.div`
  width: 343px;
  height: 485px;
  background: white;
  border-radius: 15px;
  padding: 28px;
  margin-top: 2rem;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    margin-top: 0;
    margin: auto;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Title = styled.p`
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 15px;
    line-height: 20px;
  }
`

const Input = styled.input`
  border-color: #BBBBBB;
  border-width: 1px;
  padding: 7px 16px;
  margin-bottom: 20px;
  border-radius: 5px;
`

const TextArea = styled.textarea`
  border-color: #BBBBBB;
  border-width: 1px;
  padding: 7px 16px;
  margin-bottom: 20px;
  border-radius: 5px;
  height: 141px;
`

export default FormCard
