import React from 'react'
import styled from 'styled-components'
import Card from '../../../components/Card'
import { childVariants } from '../../../framerOptions'
import { motion } from 'framer-motion'
import { useRequest } from '../../../fetchboi/hooks'

export default function PayCard () {
  const now = new Date();
  const [congresses] = useRequest({ url: `/congresses` })
  const filteredCongresses = congresses?.filter(congress => {
    const endsAt = new Date(congress.EndsAt);
    return endsAt > now && congress.LandingButtonURL !== '' && congress.Slug.startsWith("veterinario");
  })
  console.log("congreses: ", congresses)
  console.log("filteredCongresses: ", filteredCongresses)
  return (
   <Container variants={childVariants}>
     <StyledCard>
      <Title>Pago de Congresos</Title>
       <ul>
        {filteredCongresses?.map((congress) => (
          <li key={congress.Slug}><b>{congress.Name}:</b> <a href={congress.LandingButtonURL} target="_blank" rel="noopener noreferrer">Pagar</a></li>
        ))}
       </ul>
     </StyledCard>
   </Container>
  )
}

const Container = styled(motion.div)`
  text-align: left;
  margin: 3.2rem 0;

  li {
    padding-bottom: 1rem;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

const StyledCard = styled(Card)`
  height: 37rem
`

const Title = styled.h4`
  font-weight: 800;
  font-size: 2rem;
  color: #393939;
  text-align: center;
  margin-bottom: 1rem;
`