import React from 'react'
import { MD_BREAKPOINT } from '../../breakpoints'
import styled from 'styled-components'

const ImageCard = ({ image, text }) => {
  return (
    <CardWrapper className='w-full sm:w-80 text-center flex flex-col justify-center mb-8 sm:mb-0'>
      <IMG src={image} />
      <Text className='text-primary w-60 mx-auto mt-7'>
        {text}
      </Text>
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    width: 100%;
    margin-bottom: 0;
  }
`

const Text = styled.p`
  width: 240px;
  color: #2B3D6B;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  margin: 0 auto;
  margin-top: 2.75rem;
`

const IMG = styled.img`
  width: 74px;
  height: 88px;
  margin: 0 auto;
`

export default ImageCard
