import { useCallback, useContext, useEffect, useState } from 'react'
import { FetchboiContext } from './context'

export function useRequest ({ url, method: initialMethod = 'get', auto = true, initial = null, ...props }) {
  const client = useClient(props)

  const [instance, setInstance] = useState(initial)
  const [loading, setLoading] = useState(auto)
  const [error, setError] = useState(null)

  const request = useCallback(async ({ data, method, background = false } = {}) => {
    if (!background) {
      setLoading(true)
    }

    try {
      const response = await client.request({ url, method: method || initialMethod, data })
      setInstance(response)
      return response
    } catch (e) {
      setError(e)
    } finally {
      if (!background) {
        setLoading(false)
      }
    }
  }, [url])

  request.get = (config) => {
    return request({ ...config, method: 'get' })
  }

  request.post = (data, config) => {
    return request({ ...config, data, method: 'post' })
  }

  request.put = (data, config) => {
    return request({ ...config, data, method: 'put' })
  }

  request.delete = (config) => {
    return request({ ...config, method: 'delete' })
  }

  useEffect(() => {
    if (auto) {
      request({ method: initialMethod })
    }
  }, [auto, request, initialMethod])

  return [instance, loading, { request, error }]
}

export function useClient ({ extract, onError } = {}) {
  const { axios, extract: defaultExtract, onError: defaultOnError } = useContext(FetchboiContext)

  extract = extract || defaultExtract
  onError = onError || defaultOnError

  const wrap = (axiosMethod) => {
    async function fn () {
      try {
        console.log(arguments)
        const response = await axiosMethod.apply(null, arguments)
        return extract ? response.data[extract] : response.data
      } catch (e) {
        if (onError) {
          onError(e)
        }

        throw e
      }
    }

    return fn
  }

  return {
    get: wrap(axios.get),
    post: wrap(axios.post),
    delete: wrap(axios.delete),
    put: wrap(axios.put),
    request: wrap(axios.request),
  }
}
