import React, { useState } from 'react'
import styled from 'styled-components'
import Card from '../../../components/Card'
import { childVariants } from '../../../framerOptions'
import { motion } from 'framer-motion'
import Button from '../../../components/Button'

export default function DepositCard () {
  const [loading, setLoading] = useState(false)

  return (
   <Container variants={childVariants}>
     <StyledCard>
       <Title>Pago General</Title>
       <ul>
         <li><b>Transferencia Chile</b></li>
         <li><b>Banco:</b> Itaú</li>
         <li><b>Titular:</b> Tischler & Asociados, SpA.</li>
         <li><b>Cuenta Corriente:</b> 206568928</li>
         <li><b>RUT:</b> 76.384.378-5</li>
       </ul>
       <br/>
       <b>Enviar comprobante a:</b> <br/>
       <a href="mailto:contacto@congresoveterinario.cl">contacto@congresoveterinario.cl</a>
       <br/>
       <br/>
       <b>Pagar con tarjetas:</b> <br/>
       <Button external loading={loading} to="https://www.flow.cl/btn.php?token=vfs9g5s" onClick={() => setLoading(true)} color="#1C8DD7">Pagar</Button>
     </StyledCard>
   </Container>
  )
}

const Container = styled(motion.div)`
  margin: 3.2rem 0;
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

const StyledCard = styled(Card)`
  height: 37rem
`

const Title = styled.h4`
  font-weight: 800;
  font-size: 2rem;
  color: #393939;
  text-align: center;
  margin-bottom: 1rem;
`