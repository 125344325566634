import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import { MD_BREAKPOINT } from '../../breakpoints'
import Lottie from '../../components/Lottie'

export default function PaymentFailure () {
  return (
   <Container>
      <Layout>
        <Layout.Title>Pagar Rechazado</Layout.Title>
        <Layout.Container maxWidth={900}>
          <ContentContainer>
            <Title>¡Pago Rechazado!</Title>
            <Text>Por algún motivo, su pago ha sido rechazado. Si el problema persiste, puede intentar realizar una transferencia electrónica.</Text>
            <LottieContainer>
              <Lottie animation="error" width="100%" height="100%" autoPlay loop={false}/>
            </LottieContainer>
          </ContentContainer>
        </Layout.Container>
      </Layout>
    </Container>
  )
}

const Container = styled.div`
  background: #F2F5F8;
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 6rem;
  padding-bottom: 17.6rem;
  text-align: center;
`

const Title = styled.h3`
  font-weight: 800;
  font-size: 3.7rem;
  color: #393939;
  
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 3rem;
  }
`
const Text = styled.p`
  margin-top: 1rem;
  font-weight: 100;
  font-size: 2rem;
  color: #393939;
  
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 1.5rem;
  }
`

const LottieContainer = styled.div`
  width: 100%;
  max-width: 400px;
  height: 300px;
`
