import React from 'react'
import styled from 'styled-components'
import { Icon } from 'rsuite'
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../../breakpoints'

export default function Card ({ text, icon }) {
  return (
    <Container>
      <StyledIcon icon={icon} size="2x" color="#fff"/>
      <Text>{ text }</Text>
   </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 28.7rem;
  padding: 1rem;
  
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    margin: 1rem 0;
  }
  @media screen and (max-width: ${SM_BREAKPOINT}em) {
    margin: 0.5rem 0;
  }
`

const StyledIcon = styled(Icon)`
  color: #FFF;
  margin-right: 1rem;
`

const Text = styled.p`
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  
  color: #FFFFFF;
`
