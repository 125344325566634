import React from 'react'
import Container from '../Layout/Container'
import Button from './Button'
import Title from './Title'
import { MD_BREAKPOINT } from '../../breakpoints'
import styled from 'styled-components'
import {Icon} from "rsuite";
import {motion} from "framer-motion";

const Footer = () => {
  return (
    <Container maxWidth={1000}>
      <FooterWrapper >
        <Title style={{ color: "#FFF", margin: '0 auto'}} >
          Participe del mejor congreso veterinario de negocios de LATAM
        </Title>
         
        <ButtonWrapper className='w-full flex justify-center mt-8'>
          <a href="cartagena2022#contact">
            <Button buttonText='Póngase en contacto'/>
          </a>
        </ButtonWrapper>

        <SocialIcons>
          <FacebookLink whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} href="https://facebook.com/congresoveterinariochile/" target="_blank" rel="noopener noreferrer">
            <Icon size="2x" icon="facebook-official"/>
          </FacebookLink>

          <YoutubeLink whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} href="https://youtube.com/channel/UCj5fkefs4gFOZtwCTdsVTHQ" target="_blank" rel="noopener noreferrer">
            <Icon size="2x" icon="youtube"/>
          </YoutubeLink>

          <InstagramLink whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} href="https://instagram.com/congresoveterinario.cl?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
            <Icon size="2x" icon="instagram"/>
          </InstagramLink>
        </SocialIcons>

      </FooterWrapper>      
    </Container>
  )
}

const FooterWrapper = styled.div`
  width: 700px;
  margin: 0 auto;
  text-align: center;
  padding: 187px 0;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`

const SocialIcons = styled.div`
  padding: 2rem 0;
  text-align: center;
`

const FacebookLink = styled(motion.a)`
  display: inline-block;
  color: #FFF !important;
`
const InstagramLink = styled(motion.a)`
  display: inline-block;
  color: #FFF !important;
  margin-left: 2rem;
`
const YoutubeLink = styled(motion.a)`
  display: inline-block;
  color: #FFF !important;
  margin-left: 2rem;
`

export default Footer
