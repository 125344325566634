import React from 'react'
import { MD_BREAKPOINT } from '../../breakpoints'
import styled from 'styled-components'

const Title = ({ children, style }) => {
  return (
    <TextTitle
      style={{ ...style }}
    >
      {children}
    </TextTitle>
  )
}


const TextTitle = styled.p`
  font-weight: 800;
  font-size: 33px;
  line-height: 45px;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 28px;
    line-height: 29px;
  }
`

export default Title
