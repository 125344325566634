import React from 'react'
import styled from 'styled-components'
import Layout from '../../../components/Layout'
import { LG_BREAKPOINT } from '../../../breakpoints'
import Buttons from './Buttons'
import Features from './Features'
import Title from './Title'


export default function Download () {
  return (
   <Container>
     <Layout.Container maxWidth={1200} style={{ paddingBottom: '2rem' }}>
       <TopSectionContainer>
         <Title/>
         <Buttons/>
       </TopSectionContainer>
    </Layout.Container>
     <Layout.Container maxWidth={1200}>
       <Features/>
     </Layout.Container>
   </Container>
  )
}

const Container = styled.div`
  background: #1C8DD7;
  padding: 2.1em;
`

const TopSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media screen and (max-width: ${LG_BREAKPOINT}em) {
    flex-direction: column;
  }
`

