import React from 'react'
import Container from "../Layout/Container"
import ImageCard from "./ImageCard"
import Title from "./Title"
import styled from 'styled-components'
import Hotel from '../../assets/images/hotel.svg'
import Money from '../../assets/images/money.svg'
import Vet from '../../assets/images/vet.svg'
import Video from "./Video";

const Participate = ({videoID}) => {
  return (
    <Container maxWidth={1100} style={{ background: '#FFF'}}>
      <Wrapper>
        <Video id={videoID}/>

        <Title style={{ color: '#2B3D6B', marginTop: '2.75rem'}}>
          ¿Por qué participar?
        </Title>
        <CardWrapper>
          <ImageCard
            image={Hotel}
            text='Podrás ser parte de una gran experiencia en uno de los mejores hoteles'
          />
          <ImageCard
            image={Money}
            text='Aprenderás cómo potenciar el negocio y mejorar la atención veterinaria'
          />
          <ImageCard
            image={Vet}
            text='Conocerás historias de éxito y compartirás con veterinarios de diferentes países'
          />
        </CardWrapper>
        <More>Y mucho más...</More>
      </Wrapper>
    </Container>
    
  )
}

const Wrapper = styled.div`
  text-align: center;
  margin: 50px 0;
  width: 100%;
`

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5rem;
`

const More = styled.p`
  color: #2B3D6B;
  font-size: 16px;
  line-height: 18px;
  margin-top: 2.75rem;
  font-weight: 700;
`

export default Participate
