import React, { createContext } from 'react'

export const FetchboiContext = createContext({})

export default function FetchboiProvider ({ axios, extract, onError, children }) {
  return (
    <FetchboiContext.Provider value={{
      axios,
      extract,
      onError,
    }}>
      {children}
    </FetchboiContext.Provider>
  )
}
