import React from 'react'
import Container from '../Layout/Container'
import { MD_BREAKPOINT, LG_BREAKPOINT } from '../../breakpoints'
import styled from 'styled-components'
import CheckedText from './CheckedText'
import FormCard from './FormCard'
import Title from './Title'

const FormSection = () => {
  return (
    <Container maxWidth={1000}>
      <Wrapper id="contact" section="contact">
        <TextWrapper>
          <Title style={{ color: '#fff', margin: "20px 0", textAlign: "left"}}>
            Sé parte del congreso veterinario más importante
          </Title>
          <P>
            Este 26, 27 y 28 de octubre, podrás participar de la experiencia empresarial del mundo veterinario en el exclusivo Hotel Plaza Santa Cruz en Colchagua.
          </P>
          <CheckedText style={{ padding: "40px 0 16px"}}>
            Más de 10 versiones realizadas.
          </CheckedText>
          <CheckedText style={{ paddingBottom: "16px"}}>
            300 asistentes promedio por congreso.
          </CheckedText>
          <CheckedText style={{ paddingBottom: "16px"}}>
            12 charlas y talleres con foco en mejorar los negocios veterinarios.
          </CheckedText>
          <CheckedText style={{ paddingBottom: "16px"}}>
            Promociones exclusivas de las mejores marcas.
          </CheckedText>

        </TextWrapper>
        <CardWrapper >
          <FormCard/>
        </CardWrapper>
      </Wrapper>      
    </Container>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 8rem;
  justify-content: space-between;
`

const TextWrapper = styled.div`
  width: 510px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  @media screen and (max-width: ${LG_BREAKPOINT}em) {
    width: 450px;
  }
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    width: 100%;
  }
`

const CardWrapper = styled.div`
  width: 343px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    margin: 0 auto;
    width: 100%;
  }
`

const P = styled.p`
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #FFF;
  margin-top: 20px;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 16px;
    line-height: 18px;
  }
`
export default FormSection
