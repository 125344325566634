import React from 'react'
import styled from 'styled-components'
import { Loader } from 'rsuite'

const Button = ({ onClick, buttonType = "button", buttonText, disabled, loading, ...rest }) =>
  {

  return (
    <ButtonWrapper
      onClick={onClick}
      type={buttonType}
      {...rest}
    >
      <p >
        {loading ?  <Loader size="sm" speed="slow"/> : buttonText}
      </p>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.button`
  background: #EAC36A;
  border-radius: 5px;
  border: 0;
  padding: 8px 16px;
  color: #000;
  cursor: pointer;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;

  &:hover {
    background-color: rgb(239 209 142);
  }

  &:disabled {
    background-color: rgba(60,93,150,0.62);
  }

  &:focus {
    outline: none;
  }
`

export default Button
