import React, { useState } from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import Card from '../../components/Card'
import AmountForm from './AmountForm'
import PaypalButton from './PaypalButton'
import { childVariants, parentVariants } from '../../framerOptions'
import { motion } from 'framer-motion'

export default function PaymentPaypal () {
  const [amount, setAmount] = useState(0)
  return (
   <Container>
     <motion.div variants={parentVariants} initial="hidden" animate="visible">
      <Layout>
        <Layout.Title>Pagar Congreso</Layout.Title>
        <Layout.Container maxWidth={700}>
          <ContentContainer>
            <motion.div variants={childVariants}>
              <Card>
                {amount ? <PaypalButton amount={amount}/> : <AmountForm onSubmit={setAmount}/>}
              </Card>
            </motion.div>
          </ContentContainer>
        </Layout.Container>
      </Layout>
     </motion.div>
    </Container>
  )
}

const Container = styled.div`
  background: #F2F5F8;
`

const ContentContainer = styled.div`
  padding: 10rem 0;
`