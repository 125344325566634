import React from 'react'
import CheckedText from "./CheckedText"
import Container from "../Layout/Container"
import Button from "./Button"
import Title from "./Title"
import styled from 'styled-components'

const Activities = () => {
  return (
    <div style={{ background: "#2B3D6B"}}>
      <Container maxWidth={800} >
        <div style={{ padding: "50px 0", textAlign: "center"}}>
          <Title style={{ color: "#FFF"}}>
            Algunas actividades del programa
          </Title>
          <div style={{ textAlign: "left"}}>
            <CheckedText style={{ marginTop: '50px'}}>
              Cómo deslumbrar a nuestros clientes en la sala de consulta
            </CheckedText>
            <CheckedText style={{ marginTop: '50px'}}>
              ¿Como podemos lograr equipos de alto desempeño en nuestros centros veterinarios?
            </CheckedText>
            <CheckedText style={{ marginTop: '50px'}}>
              Los tres pilares básicos de crecimiento en nuestros centros veterinarios.
            </CheckedText>
            <CheckedText style={{ marginTop: '50px'}}>
              Los grandes problemas de precios y errores que cometemos los veterinarios
            </CheckedText>
          </div>
      </div>
      <ButtonWrapper className="flex flex-row justify-center mt-14">
        <a href='https://trazoevents.com/landing/veterinario-cartagena-2022/program' target='_blank'>
          <Button buttonText="Ver programa de actividades"/>
        </a>
      </ButtonWrapper>
      </Container>
    </div>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  marginTop: 50px;
  padding-bottom: 50px;
`

export default Activities
