import React from 'react'
import styled from 'styled-components'

export default function Card ({ children, ...rest }) {
  return (
   <Container {...rest}>
     {children}
   </Container>
  )
}

const Container = styled.div`
  background: #FFFFFF;
  border-radius: 12px;
  padding: 3rem;
  position: relative;
  overflow: hidden;
`