import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Loader, Button as RButton } from 'rsuite'
import { motion } from 'framer-motion'

function Button ({ children, color, loading, onClick, disabled, rounded, outline, small, wrap, circular, transparent, block, to, external, ...rest }) {
  const handleClick = () => {
    if (!loading && !disabled && onClick) {
      onClick()
    }
  }

  const _button = (
    <motion.div style={{ display: 'inline-block' }} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
      <Container
        color={color}
        disabled={loading || disabled}
        rounded={rounded}
        onClick={handleClick}
        outline={outline}
        small={small}
        wrap={wrap}
        circular={circular}
        transparent={transparent}
        block={block}
        {...rest}
      >
        <Content hide={loading}>
          {children}
        </Content>
        <LoaderContainer visible={loading}>
          <Loader size="sm" speed="Xsmall"/>
        </LoaderContainer>
      </Container>
    </motion.div>
  )

  if (to && !external) {
    return (
      <Link to={to}>
        {_button}
      </Link>
    )
  } else if (to && external) {
    return (
      <a href={to} {...rest}>
        {_button}
      </a>
    )
  }
  return _button

}

const Container = styled(RButton)`
  background-color: ${props => props.color} !important;
  overflow: hidden;
  border: none;
  border-radius: 5px;
  outline: none !important;
  padding: 10px 20px;
  min-width: 200px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  
  &:hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
  }
  
  ${({ rounded }) => rounded && 'border-radius: 100px;'}
  
  ${({ small }) => small && `
    font-size: 12px;
    padding: 10px 10px;
    min-width: 200px;
  `}
  
  ${({ wrap }) => wrap && `
    min-width: inherit;
  `}
  
  ${({ block }) => block && `
    display: block;
    width: 100%;
  `}
  
  ${({ circular }) => circular && `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    min-width: inherit;
    border-radius: 22px;
  `}
  
  ${({ outline }) => outline && `
    color: #fff !important;
    border: 2px solid #fff !important;
  `}
  
  ${({ transparent }) => transparent && `
    background: transparent !important;
  `}
  
  ${({ disabled }) => disabled && `
      cursor: not-allowed !important;
      opacity: 0.8;
  `}
`

const Content = styled.span`
  color: #FFF;
  text-transform: uppercase;
  
  ${({ hide }) => hide && 'opacity: 0;'}
`

const LoaderContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  
  ${({ visible }) => visible && 'display: flex;'}
`

Button.defaultProps = {
  loading: false,
  onClick: null,
  disabled: false,
  block: false,
  rounded: false,
  outline: false,
  small: false,
  to: null,
}

export default Button