import React from 'react'
import styled from 'styled-components'
import Card from '../Card'

export default function Features () {
  return (
   <Container>
     <Card
       text="Visualiza el programa de actividades"
       icon="calendar-check-o"
     />
     <Card
       text="Obtén tu ticket QR para ingresar al evento"
       icon="qrcode"
     />
     <Card
       text="Recibe notificaciones importantes en el evento"
       icon="bell-o"
     />
     <Card
       text="Promociones, beneficios, noticias y mucho más..."
       icon="bullhorn"
     />
   </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem 0;
  position: relative;
`