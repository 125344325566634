// Breakpoints in em
// They were calculated with a base font size of 16px (Default font size)

// equal to 320px
export const XS_BREAKPOINT = 20

// equal to 480px
export const SM_BREAKPOINT = 30

// equal to 768px
export const MD_BREAKPOINT = 48

// equal to 1024px
export const LG_BREAKPOINT = 64

// equal to 1200px
export const XL_BREAKPOINT = 75

// equal to 1600px
export const XXL_BREAKPOINT = 100
