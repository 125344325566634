import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { childVariants } from '../../../framerOptions'
import { Link } from 'react-router-dom'

export default function MethodCard ({ to, image, text }) {
  return (
   <Container variants={childVariants} whileHover={{ scale: 1.05, backgroundColor: '#1C8DD7' }} whileTap={{ scale: 0.9 }}>
     <Link to={to}>
       <Image src={image}/>
       <Text>{text}</Text>
     </Link>
   </Container>
  )
}

const Container = styled(motion.div)`
  background: #1C5EA5;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 3.2rem;
  
  a {
    padding: 2.5rem 3rem;
    display: block;
    text-decoration: none !important;
  }
`

const Image = styled.img`
  width: 100%;
  height: 15rem;
  object-fit: contain;
`

const Text = styled.h4`
  font-weight: 800;
  font-size: 1.8rem;
  color: #FFF;
  text-align: center;
`
