import React from 'react'
import styled from 'styled-components'
import Card from '../../../components/Card'
import { childVariants } from '../../../framerOptions'
import { motion } from 'framer-motion'

export default function DepositCard () {
  return (
   <Container variants={childVariants}>
     <StyledCard>
       <Title>Pago General</Title>
       <ul>
         <li><b>Transferencia Internacional</b></li>
       <br/>

         <li><b>Banco:</b> Itaú</li>
         <li><b>Titular:</b> Tischler & Asociados, SpA.</li>
         <li><b>Cuenta Corriente:</b> 1200250398</li>
         <li><b>Swift:</b> Itaú CL RM</li>
         <li><b>Dirección:</b> Mariano Sanchez Fontecilla 12200. Peñalolen, Santiago, Chile.</li>
       </ul>
       <br/>
       <b>Enviar comprobante a:</b> <br/>
       <a href="mailto:contacto@congresoveterinario.cl">contacto@congresoveterinario.cl</a>
     </StyledCard>
   </Container>
  )
}

const Container = styled(motion.div)`
  margin: 3.2rem 0;
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

const StyledCard = styled(Card)`
  height: 37rem
`

const Title = styled.h4`
  font-weight: 800;
  font-size: 2rem;
  color: #393939;
  text-align: center;
  margin-bottom: 1rem;
`