import React from 'react'
import styled from 'styled-components'
import { Icon } from 'rsuite'
import Layout from './index'
import { motion } from 'framer-motion'

export default function Footer () {
  return (
   <Container>
     <Layout.Container>
       <SocialIcons>
         <FacebookLink whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} href="https://facebook.com/congresoveterinariochile/" target="_blank" rel="noopener noreferrer">
           <Icon size="2x" icon="facebook-official"/>
         </FacebookLink>

         <YoutubeLink whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} href="https://youtube.com/channel/UCj5fkefs4gFOZtwCTdsVTHQ" target="_blank" rel="noopener noreferrer">
           <Icon size="2x" icon="youtube"/>
         </YoutubeLink>

         <InstagramLink whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} href="https://instagram.com/congresoveterinario.cl?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
           <Icon size="2x" icon="instagram"/>
         </InstagramLink>
       </SocialIcons>
     </Layout.Container>
   </Container>
  )
}

const Container = styled.div`
  background: #0C2845;
  padding: 2rem 0;
`

const SocialIcons = styled.div`
  padding: 2rem 0;
  text-align: center;
`

const FacebookLink = styled(motion.a)`
  display: inline-block;
  color: #FFF !important;
`
const InstagramLink = styled(motion.a)`
  display: inline-block;
  color: #FFF !important;
  margin-left: 2rem;
`
const YoutubeLink = styled(motion.a)`
  display: inline-block;
  color: #FFF !important;
  margin-left: 2rem;
`