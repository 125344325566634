import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { parentVariants } from '../../../framerOptions'
import Layout from '../../../components/Layout'
import { Col, Row } from 'rsuite'
import MethodCard from '../MethodCard'
import paypalImage from '../assets/images/paypal.png'
import webpayImage from '../assets/images/webpay.png'

export default function Methods () {
  return (
    <Container>
      <Layout.Container maxWidth={1000}>
        <motion.div variants={parentVariants} initial="hidden" animate="visible">
          <Row gutter={32}>
            <Col md={12}>
              <MethodCard image={webpayImage} text="Pago Nacional Chile" to="/payment/chilean"/>
            </Col>
            <Col md={12}>
              <MethodCard image={paypalImage} text="Pago Internacional" to="/payment/foreigner"/>
            </Col>
          </Row>
        </motion.div>
      </Layout.Container>
    </Container>
  )
}

const Container = styled.div`
  padding: 8rem 0 14.4rem;
`