import React from 'react'
import ImageBackground from '../../components/Cartagena2022/ImageBackground'
import Contact from '../../components/Cartagena2022/Contact'
import FormSection from '../../components/Cartagena2022/FormSection'
import Participate from '../../components/Cartagena2022/Participate'
import Activities from '../../components/Cartagena2022/Activities'
import Footer from '../../components/Cartagena2022/Footer'
import BG from '../../assets/images/bg.png'
import { BsWhatsapp } from "react-icons/bs"
import Logo from '../../assets/images/logo.svg'
import { Helmet } from 'react-helmet'

export default function Cartegena2022 () {
  const convertDangerousHtmlToChildren = node => {
    if (node.props && node.props.dangerouslySetInnerHTML) {
      return {
        ...node,
        props: {
          ...node.props,
          dangerouslySetInnerHTML: undefined,
          children: node.props.dangerouslySetInnerHTML.__html,
        },
      };
    }
    return node;
  };

  return (
    <div style={{ background: '#FFF', fontFamily: "-apple-system, 'Open Sans', sans-serif !important"}}>
      <Helmet>
        {convertDangerousHtmlToChildren(<script
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WRZSBQT');
            `,
            }}
        />)}
      </Helmet>
      <ImageBackground banner={BG}>
        <Contact logo={Logo}/>
        <FormSection/>
      </ImageBackground>
      <Participate/>
      <Activities/>
      <ImageBackground banner={BG}>
        <Footer/>
        <Contact logo={Logo}/>
      </ImageBackground>
      <a
        href="https://wa.me/56996384517"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsWhatsapp className='whatsapp-icon'/>
      </a>
    </div>
  )
}

// export default Cartegena2022

