import React from 'react'
import styled from 'styled-components'
import Layout from '../../../components/Layout'
import { motion } from 'framer-motion'
import { parentVariants } from '../../../framerOptions'
import { Col, Row } from 'rsuite'
import DepositCard from '../DepositCard'
import PayCard from '../PayCard'

export default function Options () {
  return (
   <Container>
     <Layout.Container maxWidth={1000}>
       <motion.div variants={parentVariants} initial="hidden" animate="visible">
         <Row gutter={32}>
           <Col md={12}>
             <PayCard/>
           </Col>
           <Col md={12}>
             <DepositCard/>
           </Col>
         </Row>
       </motion.div>
     </Layout.Container>
   </Container>
  )
}

const Container = styled.div`
  padding: 4.8rem 0 11.2rem;
`