import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import Methods from './Methods'

export default function PaymentMethod () {
  return (
   <Layout>
     <Layout.Title>Pagar Congreso</Layout.Title>
     <Container>
       <Layout.SubTitle
         title="Selecciona el tipo de pago según nacionalidad"
       />
       <Methods/>
     </Container>
   </Layout>
  )
}

const Container = styled.div`
  background: #F2F5F8;
`