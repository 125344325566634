import React, { forwardRef, useRef } from 'react'
import ReactLottie from 'react-lottie';
import PropTypes from 'prop-types'
import checkAnimation from './json/check'
import errorAnimation from './json/error'

const Lottie = forwardRef(function ({ width = 200, height = 200, autoPlay = false, loop = false, animation }, ref) {
  const lottieRef = useRef(null)

  const play = () => {
    lottieRef.current.reset()
    lottieRef.current.play()
  }

  if (ref) {
    ref.current = { play }
  }

  const lottieOptions = {
    loop: loop,
    autoplay: autoPlay,
    animationData: animations[animation] || null,
  };


  return (
    <ReactLottie
      ref={lottieRef}
      options={lottieOptions}
      width={width}
      height={height}
    />
  )
})

const animations = {
  check: checkAnimation,
  error: errorAnimation,
}

Lottie.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  animation: PropTypes.oneOf(['check', 'error'])
}


export default Lottie