import React from 'react'
import styled from 'styled-components'
import Layout from '../../../components/Layout'
import { MD_BREAKPOINT } from '../../../breakpoints'
import Button from '../../../components/Button'

export default function VideoLink () {
  return (
   <Container>
     <Layout.Container>
       <Content>
          <Text>Te invitamos a revisar video cápsulas del recién pasado Congreso Veterinario Virtual 2021</Text>
          <a href="https://www.youtube.com/playlist?list=PLPhmwYowElcsJxBEnn7vK3MFCxZyFUOWE" target="_blanc">
            <Button small rounded color="#FFF" style={{ width: 250 }} textColor="#000">
              <ButtonText>Ver Videos</ButtonText>
            </Button>
          </a>
       </Content>
     </Layout.Container>
   </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  background-color: #FF5959;
  z-index: 999;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-center;
  }
`

const Text = styled.p`
  color: #FFF;
  font-weight: 800;
  margin-left: 15px;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    text-align: center;
    margin-bottom: 10px;
  }
`

const ButtonText = styled.p`
  color: #000;
  font-weight: 900;
`