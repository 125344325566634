import React from 'react'
import { MD_BREAKPOINT } from '../../breakpoints'
import styled from 'styled-components'
import Check from '../../assets/images/check.svg'

const CheckedText = ({ children, style }) => {
  return (
    <Wrapper style={{ ...style }}>
      <ImageWrapper>
       <IMG src={Check} />
      </ImageWrapper>
      <Text>
        {children}
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: flex-start;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    width: 22px;
  }
`

const IMG = styled.img`
  width: 22px;
  height: 22px;
`

const Text = styled.p`
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  margin-left: 16px;
  color: #FFF;
  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 16px;
    line-height: 18px;
    
  }
`

export default CheckedText
