import React from 'react'

import BG from '../../assets/images/colchagua.png'
import Logo from '../../assets/images/colchaguaLogo.png'

import { BsWhatsapp } from "react-icons/bs"
import ImageBackground from "../../components/Colchagua2022/ImageBackground";
import Contact from "../../components/Colchagua2022/Contact";
import FormSection from "../../components/Colchagua2022/FormSection";
import Participate from "../../components/Colchagua2022/Participate";
import Activities from "../../components/Colchagua2022/Activities";
import Footer from "../../components/Colchagua2022/Footer";

export default function Colchagua2022 () {
  
  return (
    <div style={{ background: '#FFF', fontFamily: "-apple-system, 'Open Sans', sans-serif !important"}}>
      <ImageBackground banner={BG}>
        <Contact logo={Logo}/>
        <FormSection/>
      </ImageBackground>
      <Participate videoID={'8bMX8bh5KeE'}/>
      <Activities/>
      <ImageBackground banner={BG}>
        <Footer/>
        <Contact logo={Logo}/>
      </ImageBackground>
      <a
        href="https://wa.me/56996384517"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsWhatsapp className='whatsapp-icon'/>
      </a>
    </div>
  )
}

// export default Cartegena2022

