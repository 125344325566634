import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import Options from './Options'

export default function ChileanMethod () {
  return (
    <Container>
      <Layout>
        <Layout.Title>Pagar Congreso</Layout.Title>
          <Layout.SubTitle
           title="Pago Nacional Chile"
           description="Utiliza esta opción si debes pagar un congreso y tienes tarjetas emitidas en Chile. También puedes realizar una transferencia electrónica."
          />
          <Options/>
      </Layout>
    </Container>
  )
}

const Container = styled.div`
  background: #F2F5F8;
`