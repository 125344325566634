import React from 'react'
import Container from '../Layout/Container'
import Logo from '../../assets/images/logo.svg'
import { MD_BREAKPOINT } from '../../breakpoints'
import styled from 'styled-components'

function Video ({ id='H9gfcaX-B9Y' }) {
    return (
        <Container maxWidth={1000}>
            <VideoContainer className="video-responsive">
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Cartagena Business"
                />
            </VideoContainer>
        </Container>
    )
}

const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
`
const TextContainer = styled.div`
 text-align: right;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    justify-content: space-between;
    width: 100%;
    text-align: center;
    margin-top: 0.75rem
  }
`

const P = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #FFF;

  @media screen and (max-width: ${MD_BREAKPOINT}em) {
    font-size: 20px;
    line-height: 28px;
  }
`

const LogoImg = styled.img`
  width: 166px;
  height: 55px;
`

export default Video
